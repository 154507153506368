var wipwrp = window.wipwrp || {};

/* ------------------------------------------------------------
	Utils Methods
	------------------------------------------------------------ */


	wipwrp.utils = (function($){


		/* ----------------- private vars ---------------*/

		var	self				=	{};

		/* ----------------- public methods ---------------*/



		self.getQueryVariable = function(variable){
			var query = window.location.search.substring(1);
			var vars = query.split("&");
			for (var i=0;i<vars.length;i++) {
				var pair = vars[i].split("=");
				if(pair[0] == variable){return pair[1];}
			}
			return(false);
		}

		self.isScrolledIntoView = function(obj, tgtpoint) {
			(tgtpoint != 'undefined' && tgtpoint != '') ? keypoint='top':keypoint = tgtpoint;
			var docViewTop = ($(window).scrollTop() + $(window).height()) - 3*($(window).height()/4);
			var docViewBottom =  ($(window).scrollTop() + $(window).height()) - ($(window).height()/4);
			if(keypoint=='top') {
				var objTop = $(obj).offset().top;
				var result = ((objTop <= docViewBottom) && (objTop >= docViewTop));
			} else if(keypoint=='pre') {
				var objTop = $(obj).offset().top;
				var result = (objTop <= $(window).scrollTop());
			} else {
				var objCenter = ($(obj).offset().top + $(obj).outerHeight()) - ($(obj).outerHeight()/2);
				var result = ((objCenter <= docViewBottom) && (objCenter >= docViewTop));
			}
			return result;
		}

		self.checkLikeRadio = function(){
			if ($('.check-radiolike').length > 0){
				$('.check-radiolike').on('change',function() {
					$('.check-radiolike[name="'+this.name+'"]').filter(':checked').not(this).removeAttr('checked');
				});
			}
	}	// _checkLikeRadio();

	self.smartmail = function(selector){
		/**
		* es. <a class="smartmail" data-name="info" data-dom="wipitalia.it" href="javascript:void(0);">info @ wipitalia.it</a>
		*/
		$(selector).on('click', function(e) {
			var name = $(this).data('name');
			var domain = $(this).data('dom');
			var url = 'mailto:'+name+'@'+domain;
			window.open(url,"_top");
		});
	}

	self.is_touch_device = function() {
		return 'ontouchstart' in window        // works on most browsers 
			|| navigator.maxTouchPoints;       // works on IE10/11 and Surface
	};

	self.datemask = function(selector){
		if($(selector).length > 0){
			$(selector).mask( '99/99/9999' );
		}
	};	// _datemask();

	self.relationField = function(){
		/**
		* testato su select e radio
		* select -> [data-relation="NOME_GRUPPO"]
		* contenuto da mostrare/nascondere: [data-relationgroup="NOME_GRUPPO"][data-related="OPTION_SELECTED"]
		* apr.2016 - update: posso aggiungere più valori data-related, separati da spazio semplice
		*
		*/
		var _group, _target;

		$('body').on('change','[data-relation]',function(){
			_group = $(this).data('relation');
			_toValidate = $(this).data('relator');

			if($(this).is('[type="checkbox"]') && !$(this).data('relator')) {
				// it's a checkbox with no alternatives (hide it on css)
				$('[data-relationgroup="'+_group+'"]').toggle().toggleClass('novalidate');
			} else {
				// it's a radio or select
				$('[data-relationgroup="'+_group+'"]:not([data-related*="'+_toValidate+'"])').hide().addClass('novalidate');
				$('[data-relationgroup="'+_group+'"][data-related*="'+_toValidate+'"]').show().removeClass('novalidate');
			}
		});
	};

	self.toggleSystem = function(){

		var _class, _target;

		$('[data-toggleclass]').on('click',function(e){
			_class = $(this).data('toggleclass');
			_target = $(this).data('toggletarget') ? $($(this).data('toggletarget')) : $(this);
			if (_target.hasClass(_class)) {
				_target.removeClass(_class);
			}else{
				_target.addClass(_class);
			}
		});
	}

	self.filterList = function(triggers, list){
		//kisses to  http://nexts.github.io/Jets.js/
		var _style;
		$(triggers).on('click',function(){
			$(triggers).removeClass('active');
			$(this).addClass('active');
			if(_style){_style.remove()}

				if($(this).data('filtering')!==""){
					_style = $('<style id="flt_style">'+list+'>:not([data-filter="'+$(this).data('filtering')+'"]){display:none};</style>').insertBefore(list);
				}
			});
	}

	self.lowercaseControl = function(selector){
		$(selector).on('blur',function(){
			var _newstring = $(this).val().toLowerCase();
			$(this).val(_newstring);
		});
	}

	self.uppercaseControl = function(selector){
		$(selector).on('blur',function(){
			var _newstring = $(this).val().toUpperCase();
			$(this).val(_newstring);
		});
	}

	self.capitalizeControl = function(selector){
		$(selector).on('blur',function(){
			var strtest = $(this).val();
			if (!strtest.replace(/\s/g, '').length) {
				$(this).val('');
			}else{
				var _newstring = $(this).val().toLowerCase();
				var _arr = _newstring.split(' ');
				_newstring = "";
				$.each(_arr,function(i){
					_newstring+=_arr[i].substring(0,1).toUpperCase()+_arr[i].substring(1)+' ';
				});
				$(this).val(_newstring);
			}
		});
	}

	self.getBrowserDimensions = function() {
		return {
			width: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth),
			height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
		};
	}

	self.tooltip = function(){
		var tooltip =  {}
		tooltip.obj ,
		target  = false,
		tooltip = false,
		title   = false;

		$( '[rel~=tooltip]' ).on( 'mouseenter', function() {
			target  = $( this );
			tip     = target.attr( 'title' );
			tooltip = $( '<div id="tooltip"></div>' );

			if( !tip || tip == '' )
				return false;

			target.removeAttr( 'title' );
			tooltip.css( 'opacity', 0 )
			.html( tip )
			.appendTo( 'body' );

			var init_tooltip = function()     {
				if( $( window ).width() < tooltip.outerWidth() * 1.5 )
					tooltip.css( 'max-width', $( window ).width() / 2 );
				else
					tooltip.css( 'max-width', 340 );

				var pos_left = target.offset().left + ( target.outerWidth() / 2 ) - ( tooltip.outerWidth() / 2 ),
				pos_top  = target.offset().top - tooltip.outerHeight() - 20;

				if( pos_left < 0 )         {
					pos_left = target.offset().left + target.outerWidth() / 2 - 20;
					tooltip.addClass( 'left' );
				}
				else
					tooltip.removeClass( 'left' );

				if( pos_left + tooltip.outerWidth() > $( window ).width() )         {
					pos_left = target.offset().left - tooltip.outerWidth() + target.outerWidth() / 2 + 20;
					tooltip.addClass( 'right' );
				}
				else
					tooltip.removeClass( 'right' );

				if( pos_top < 0 )         {
					var pos_top  = target.offset().top + target.outerHeight();
					tooltip.addClass( 'top' );
				}
				else
					tooltip.removeClass( 'top' );

				tooltip.css( { left: pos_left, top: pos_top } )
				.animate( { top: '+=10', opacity: 1 }, 50 );
			};

			init_tooltip();
			$(window).resize( init_tooltip );

			var remove_tooltip = function() {
				tooltip.animate( { top: '-=10', opacity: 0 }, 50, function()
				{
					$( this ).remove();
				});

				target.attr( 'title', tip );
			};

			target.on( 'mouseleave', remove_tooltip );
			tooltip.on( 'click', remove_tooltip );
		});
	}	// tooltip();

	/* ----------------- public methods ---------------*/

	return self;


})(jQuery);
