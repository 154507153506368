var wipwrp = window.wipwrp || {};

wipwrp.subscription = (function($) {

	var self = {};

	self.init = function(){

		$('form').h5Validate();
		setDateField();

		if (wipwrp.pageType == 'sacerdote') {
			calcoloQuotaSacerdote();
		}

		if (wipwrp.pageType == 'laico') {
			setClone();
			calcoloQuotaLaico();
		}
	};

	function calcoloQuotaSacerdote(){
		var $preferenza = $('#preferenza');
		var $preferenzawrapper = $('#preferenza_wrapper');
		var $quota = $('#quota');
		var $saldo = $('#saldo');
		var quotaVal = 0;
		var anticipoVal = parseFloat($('#anticipo').val());

		var tipostanza = 1;

		$('#form_sacerdote').on('change','[name="stanza"]',function(){
			tipostanza = $(this).val();

			if(tipostanza == '2'){

				console.log(tipostanza);
				$preferenzawrapper.removeClass('hidden');
			}else{
				$preferenza.empty();
				$preferenzawrapper.addClass('hidden');
			}
			quotaVal = parseFloat($quota.data('val'+tipostanza));
			$quota.val(quotaVal);
			$saldo.val(quotaVal - anticipoVal);

		});

		$('#form_sacerdote [name="stanza"][value="1"]').trigger('change');
	}

	/****/

	function calcoloQuotaLaico(){
		var $quota = $('#quota');
		var quotaInteraVal = parseFloat($('#quota').data('val1'));
		var quotaDoppiaeVal = parseFloat($('#quota').data('val2'));
		var quotaFamVal = 0;
		var $saldo = $('#saldo');
		var anticipoVal = parseFloat($('#anticipo').val());
		var single = true;
		var nfamiliari = 0;

		$quota.val(quotaInteraVal);
		$saldo.val(quotaInteraVal - anticipoVal);

		$('#form_laico').on('change','.trigger-calc', function(){


			quotaFamVal = 0;
			for (var i = 0; i < $(".fam-item").length; i++) {
				if ($(".fam-item").eq(i).find('[name*="fnome"]').val() !== "") {
					quotaFamVal = quotaFamVal + ritornaQuotaLaico();
					console.log(quotaFamVal);
				}
			}

			$quota.val(quotaFamVal + ritornaQuotaLaico());
			$saldo.val(quotaFamVal + ritornaQuotaLaico() - anticipoVal);
		});
	}

	function ritornaQuotaLaico(){
		var quotaInteraVal = parseFloat($('#quota').data('val1'));
		var quotaDoppiaeVal = parseFloat($('#quota').data('val2'));

		for (var i = 0; i < $(".fam-item").length; i++) {
			if ($(".fam-item").eq(i).find('[name*="fnome"]').val() !== "") {
				return quotaDoppiaeVal;
			}
		}
		return quotaInteraVal;

	}

	/**
	* REF: http://jsfiddle.net/mjaric/tfFLt/
	*/

	var regex = /^(.+?)(\d+)$/i;
	var cloneIndex = $(".fam-item").length;

	function setClone(){
		$("#fam_wrapper").on("click",'.add-row', clonaRiga);
		$("#fam_wrapper").on("click",'.remove-row', rimuoviRiga);
	}

	function clonaRiga(){
		$(this).parents(".fam-item").clone()
		.appendTo("#fam_wrapper")
		.attr("id", "fam_wrapper" +  cloneIndex)
		.find("*")
		.each(function() {
			var id = this.id || "";
			var match = id.match(regex) || [];
			if (match.length == 3) {
				this.id = match[1] + (cloneIndex);
			}
		})

		$("#fam_wrapper" +  cloneIndex + ' input').val('');

		renewControls();

		cloneIndex++;
	}

	function rimuoviRiga(){
		$(this).parents(".fam-item").remove();
		$(".fam-item:first-child [name*='fnome']").trigger('change');
	}


	function renewControls(){
		setDateField();
		wipwrp.utils.lowercaseControl('.lowercase');
		wipwrp.utils.capitalizeControl('.capitalize');
	}

	function setDateField(){
		$('.datepicker').pikaday({
			firstDay: 1,
			format: 'DD/MM/YYYY',
	        yearRange: [1900,2017],
			i18n: {
			    previousMonth : 'Mese precedente',
			    nextMonth     : 'Mese successivo',
			    months        : ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'],
			    weekdays      : ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato'],
			    weekdaysShort : ['Dom','Lun','Mar','Mer','Gio','Ven','Sab'],
			    firstDay	  : 1
			}
		}).keypress(function(e){
			e.preventDefault();
		});
	}





	//  return public methods
	return self;

})(jQuery);
