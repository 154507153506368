var wipwrp = window.wipwrp || {};

wipwrp.commons = (function($) {

	var self = {};

	self.init = function(){
		wipwrp.utils.smartmail('.smartmail');
		wipwrp.utils.lowercaseControl('.lowercase');
		wipwrp.utils.capitalizeControl('.capitalize');
		wipwrp.utils.tooltip();
	};

	//  return public methods
	return self;

})(jQuery);